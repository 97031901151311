'use client';

import React from 'react';
import { usePathname, useRouter } from 'next/navigation';
import Link from 'next/link';
import { Button } from "@/components/ui/button";
import { SquarePen } from "lucide-react";
import { useAppState } from "@/lib/utils/app-state";
import clsx from 'clsx';

interface ClientHeaderProps {
  varelaClass: string;
  session?: Record<string, unknown>; // Replace with a more specific type if available
}

const ClientHeader: React.FC<ClientHeaderProps> = ({ varelaClass, session }) => {
  const router = useRouter();
  const pathname = usePathname();
  const { isChatActivated } = useAppState();

  console.log('Pathname =>', pathname);

  const handleNewAsk = () => {
    router.push('/home');
    router.refresh(); // Refreshes the current route
  };

  const shouldDisplayNewAsk = () => pathname !== '/' || isChatActivated;

  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center space-x-6">
          <Link href="/home" className={clsx(varelaClass, 'text-[1.45rem] text-center mr-2')}>
            Fetchit
          </Link>

        {shouldDisplayNewAsk() && (
          <Button
            type="button"
            size="sm"
            className="ml-auto gap-1.5 bg-black rounded"
            onClick={handleNewAsk}
          >
            New Chat
            <SquarePen className="w-3.5 h-3.5" />
          </Button>
        )}
      </div>

      {/* Right-aligned controls */}
      {session && (
        <div className="flex items-center space-x-4">
          {/* <ModeToggle /> */}
          {/* <HistoryContainer /> */}
          {/* <LogoutButton /> */}
        </div>
      )}
    </div>
  );
};

export default ClientHeader;
