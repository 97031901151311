'use client';

import { signOut } from "next-auth/react";
import { Varela_Round } from 'next/font/google';
import { Settings } from 'lucide-react'
import { MyAccountMenu } from "../components/my-account-menu";



const varela = Varela_Round({
  weight: '400',
  subsets: ['latin'],
});

export default function LogoutButton() {
  return (
    <MyAccountMenu />
  );
}
