'use client';

import React from 'react';
import { useSession } from 'next-auth/react';
import { Button } from "@/components/ui/button";
import LogoutButton from './LogoutButton'; // Ensure this is a client component
import { useRouter } from 'next/navigation';

const AuthButtons = () => {
  const { data: session, status } = useSession();
  const router = useRouter(); // Initialize the router

  if (status === 'loading') {
    return (
      <Button variant="ghost" disabled>
        Loading...
      </Button>
    );
  }

  if (session?.user?.id) {
    return <LogoutButton />;
  } else {
    return (
      <Button
        onClick={() => router.push('/auth')}
        variant="outline"
        className="min-w-[100px] bg-blue-500 text-white text-md rounded-full text-xl"
      >
        Login
      </Button>
    );
  }
};

export default AuthButtons;
